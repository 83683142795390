import { TranslationSet } from "./translations";

export const rw_en_translations: TranslationSet = {
  chat_message_1: "The answers in this ChatBot are generated by AI.",
  chat_message_2:
    "If you think that something doesn’t look quite right or if the reply could make a significant difference to your plans/expenditure we recommend that you check the answer on our website or with our customer services.",
  chat_message_3: "Please ask me a question and I'll do my best to help.",
  suggestion_1: "Manage booking",
  suggestion_2: "Luggage allowance",
  suggestion_3: "My train was delayed",
  close_chat_alt: "Close Eurostar Chat Widget",
  eurostar_logo_alt: "Eurostar Logo",
  close_eurtostar_button_label: "Close Eurostar Chat Widget",
  toggle_chat_label: "Toggle Eurostar Chat Widget",
  chat_label: "Eurostar Chat Widget",
  chat_text_placeholder: "Got Eurostar questions?",
  send_button_text: "Send",
  source_text: "Source",
  feedback_thumbs_down: "Dislike",
  feedback_thumbs_up: "Like",
};
