import { motion } from "framer-motion";
import React, {
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { config } from "../config";
import { useLocale } from "../context/locale/locale-context";
import { ChatBox } from "./chat-box";

const WidgetContainer = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  background-color: #00286a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  font-family: "ABC Social Regular", Arial, sans-serif;
  z-index: 1000;

  &:hover,
  &:focus,
  &.focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px #00286a;
    outline-offset: -2px;
  }

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
    width: 56px;
    height: 56px;
  }

  img {
    width: 32px;
    height: 32px;
  }

  @media print {
    display: none;
  }
`;

const ChatContainer = styled(motion.div)`
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 400px;
  max-width: calc(100% - 40px);
  height: 600px;
  max-height: calc(100vh - 120px);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #00286a;
  color: white;

  img {
    height: 30px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  outline: none;

  img {
    height: 20px;
  }

  &:hover,
  &:focus,
  &.focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px #00286a;
    outline-offset: -2px;
  }
`;

const chatContainerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const getWidgetIcon = (isChatVisible: boolean): string =>
  isChatVisible
    ? `${config.widgetEndpoint}/img/cross.svg`
    : `${config.widgetEndpoint}/img/chat-filled.svg`;

const focusTrap = (
  event: FocusEvent<HTMLElement>,
  containerRef: React.RefObject<HTMLElement>
) => {
  if (
    containerRef.current &&
    !containerRef.current.contains(event.relatedTarget as Node)
  ) {
    event.stopPropagation();
    containerRef.current.focus();
  }
};

export const ChatbotExpandable = () => {
  const locale = useLocale();
  const [isChatVisible, setIsChatVisible] = useState<boolean>(() => {
    const savedState = localStorage.getItem("isChatVisible");
    return savedState === "true";
  });

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    localStorage.setItem("isChatVisible", isChatVisible.toString());
    if (isChatVisible) {
      chatContainerRef.current?.focus();
    } else {
      toggleButtonRef.current?.focus();
      toggleButtonRef.current?.classList.add("focus-visible");
    }
  }, [isChatVisible]);

  const toggleChatVisibility = useCallback(() => {
    setIsChatVisible((prev) => !prev);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Escape") {
      setIsChatVisible(false);
    }
  }, []);

  const handleOutsideClick = useCallback((event: MouseEvent | TouchEvent) => {
    if (
      chatContainerRef.current &&
      !chatContainerRef.current.contains(event.target as Node) &&
      !toggleButtonRef.current?.contains(event.target as Node)
    ) {
      setIsChatVisible(false);
    }
  }, []);

  useEffect(() => {
    if (!isChatVisible) return;

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isChatVisible, handleOutsideClick]);

  useEffect(() => {
    const handleBlur = () => {
      toggleButtonRef.current?.classList.remove("focus-visible");
    };

    const button = toggleButtonRef.current;
    button?.addEventListener("blur", handleBlur);

    return () => {
      button?.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <>
      <WidgetContainer
        data-cs-mask
        onClick={toggleChatVisibility}
        onKeyDown={(event) => {
          if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            toggleChatVisibility();
          }
        }}
        transition={{ duration: 0.3 }}
        aria-label={locale.translations["chat_label"]}
        ref={toggleButtonRef}
        aria-expanded={isChatVisible}
        id="toggle-chatbot"
      >
        <img alt="" src={getWidgetIcon(isChatVisible)} />
      </WidgetContainer>
      {isChatVisible && (
        <ChatContainer
          role="dialog"
          aria-label={locale.translations["chat_label"]}
          tabIndex={-1}
          ref={chatContainerRef}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={chatContainerVariants}
          transition={{ duration: 0.3 }}
          onKeyDown={handleKeyDown}
          onBlur={(event) => focusTrap(event, chatContainerRef)}
          data-cs-mask
        >
          <ChatHeader>
            <LogoContainer>
              <img
                src={`${config.widgetEndpoint}/img/logo.svg`}
                alt={locale.translations.eurostar_logo_alt}
              />
            </LogoContainer>
            <CloseButton
              aria-label={locale.translations.close_eurtostar_button_label}
              title={locale.translations.close_eurtostar_button_label}
              onClick={toggleChatVisibility}
            >
              <img
                src={`${config.widgetEndpoint}/img/chevron-down.svg`}
                alt=""
              />
            </CloseButton>
          </ChatHeader>
          <ChatBox />
        </ChatContainer>
      )}
    </>
  );
};
