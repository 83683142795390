import { config } from "../config";
import { addWebpageLocationHeader } from "../utils/api";
import { TraceInfo } from "./streaming-protocol-types";

interface FeedbackReqBody {
  trace_id: string;
  span_id: string;
  feedback_thumbs: boolean;
  messageId: string;
  conversationId: string;
}

interface PostFeedbackParams {
  traceInfo: TraceInfo;
  feedbackThumbs: boolean;
  messageId: string;
  conversationId: string;
}

export const postFeedback = async ({
  traceInfo,
  feedbackThumbs,
  messageId,
  conversationId,
}: PostFeedbackParams) => {
  const body: FeedbackReqBody = {
    trace_id: traceInfo.trace_id,
    span_id: traceInfo.span_id,
    feedback_thumbs: feedbackThumbs,
    messageId,
    conversationId,
  };

  const backendUrl = `${config.backendEndpoint}/api/feedback`;

  const response = await fetch(
    backendUrl,
    addWebpageLocationHeader({
      method: `POST`,
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify(body),
    }),
  );

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const text = await response.text();
  if (text.trim().toLowerCase() !== "ok") {
    throw new Error(`Unexpected response: ${text}`);
  }

  return text;
};
