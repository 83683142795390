import { Signals } from "deep-chat/dist/types/handler";
import { config } from "../config";
import { streamAnswer } from "../services/streaming-protocol";
import {
  GuardStatus,
  LocalMessage,
  PromptPayload,
  Source,
} from "../services/streaming-protocol-types";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { stripHtml } from "../utils/strip-html";
import ResponseMessage from "../components/response-message";
import { LocaleContextWrapper } from "../context/locale/locale-context";

type ResponseData = {
  text: string;
  feedbackInfo: string;
  source?: Source;
  id?: string;
};

export const formatMessages = (
  messages: LocalMessage[],
): PromptPayload["chat_history"] => {
  const localMessages = messages.filter(
    (message) => message.role !== "suggestion",
  );

  const formattedMessages = localMessages.map((message) => {
    return {
      id: message.id,
      guard_passed: message.guard_passed,
      signature: message.signature,
      timestamp: message.timestamp,
      role: message.role,
      content: (stripHtml(message.html) as string) || (message.text as string),
    };
  });

  return formattedMessages;
};

export const fetchResponse = async (
  payload: PromptPayload,
  signals: Signals,
  messages: LocalMessage[],
): Promise<LocalMessage[]> => {
  let responseData: ResponseData = {
    text: "",
    feedbackInfo: "",
    source: undefined,
  };

  signals.onOpen();

  let guardStatus: GuardStatus = "UNKNOWN";
  let feedbackInfo = undefined;
  let checkedMessages: {
    [key: string]: {
      message_id: string;
      guard_passed: GuardStatus;
      signature?: string;
      timestamp?: number;
    };
  } = {};

  let responseMessage = "";

  for await (const chunkMessage of streamAnswer(payload)) {
    switch (chunkMessage.type) {
      case "guard_pass": {
        guardStatus = chunkMessage.messages[0].guard_passed;
        // Store signatures from guard_pass
        chunkMessage.messages.forEach((msg) => {
          checkedMessages[msg.message_id] = msg;
        });
        break;
      }
      case "metadata":
        feedbackInfo = {
          traceInfo: chunkMessage.trace_info,
          id: chunkMessage.message_id,
          conversationId: payload.conversation_id,
        };
        responseData.source = chunkMessage.documents.sort(
          (a: any, b: any) => b.search_score - a.search_score,
        )[0];
        responseData.id = chunkMessage.message_id;
        responseData.feedbackInfo = JSON.stringify(feedbackInfo);
        break;
      case "answer_chunk":
        responseData.text += chunkMessage.chunk;
        break;
    }

    // prevents initially rendering empty chat message
    if (responseData.text.length > 0) {
      responseMessage = getRespnseMessageAsString(responseData);

      signals.onResponse({
        overwrite: true,
        html: responseMessage,
      });
    }
  }

  signals.onClose();

  // Update messages with checked signatures from older messages
  const updatedMessages = messages.map<LocalMessage>((msg: LocalMessage) => {
    const checkedMessage = checkedMessages[msg.id];
    if (checkedMessage) {
      return {
        ...msg,
        role: msg.role as "user" | "chatbot" | "suggestion",
        guard_passed: checkedMessage.guard_passed,
        signature: checkedMessage.signature,
        timestamp: checkedMessage.timestamp,
      };
    }
    return msg as LocalMessage; // Keep original message if not checked
  });

  // Create the new bot message
  const botMessage: LocalMessage = {
    html: responseMessage,
    id: feedbackInfo?.id,
    role: "chatbot",
    guard_passed: "UNKNOWN", // Streamed messages start as unknown
  };

  // Return all messages including the new bot message
  return [...updatedMessages, botMessage];
};

const getRespnseMessageAsString = (responseData: any) => {
  const componentString = ReactDOMServer.renderToStaticMarkup(
    <LocaleContextWrapper>
      <ResponseMessage
        feedbackInfo={responseData.feedbackInfo}
        source={responseData.source}
        html={responseData.text}
        widgetEndpoint={config.widgetEndpoint}
      />
    </LocaleContextWrapper>,
  );

  return componentString;
};
