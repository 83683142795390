import { TranslationSet } from "./translations";

export const be_nl_translations: TranslationSet = {
  chat_message_1: "De antwoorden in deze Chatbot worden gegenereerd door AI.",
  chat_message_2:
    "Als je twijfelt over bepaalde informatie of als het antwoord een aanzienlijke invloed kan hebben op je plan of uitgaven, raden we je aan de informatie op onze site of bij onze klantendient te controleren.",
  chat_message_3:
    "Aarzel niet om me een vraag te stellen en ik zal mijn best doen om je te helpen.",
  suggestion_1: "Beheer je boeking",
  suggestion_2: "Bagage",
  suggestion_3: "Mijn trein had vertraging",
  close_chat_alt: "Sluit de Eurostar chat widget",
  eurostar_logo_alt: "Eurostar-logo",
  close_eurtostar_button_label: "Sluit de Eurostar chat widget",
  toggle_chat_label: "Eurostar chat-widget openen/sluiten",
  chat_label: "Eurostar chat widget",
  chat_text_placeholder: "Heb je vragen over Eurostar?",
  send_button_text: "Sturen",
  source_text: "Bron",
  feedback_thumbs_down: "afkeuren",
  feedback_thumbs_up: "goedkeuren",
};
