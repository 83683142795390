import { DeepChat } from "deep-chat-react";
import React, { useCallback } from "react";
import { useHtmlClassUtilities } from "../config/htmlClassUtilities";
import {
  chatStyleProps,
  messageStylesProps,
  createSubmitButtonStyles,
  createTextInputProps,
} from "../styles/styleProps";
import { REQUEST_BODY_LIMITS_PROPS } from "../utils/constants";
import { formatMessages, fetchResponse } from "../utils/requestHandler";
import { useLocale } from "../context/locale/locale-context";
import { useMessageStorage } from "../context/messages/messages-context";
import { v4 } from "uuid";
import { Signals } from "deep-chat/dist/types/handler";
import { LocalMessage } from "../services/streaming-protocol-types";

export const ChatBox: React.FC = () => {
  const {
    messages,
    messagesRef,
    conversationId,
    clearAllMessages,
    setMessages,
  } = useMessageStorage();
  const locale = useLocale();
  const htmlClassUtilities = useHtmlClassUtilities();

  const handleRequest = useCallback(
    async (body: { messages: any[] }, signals: Signals) => {
      const curreUserMessage = body.messages[body.messages.length - 1];

      if (curreUserMessage.text === "/clear") {
        clearAllMessages();
        return;
      }

      const userMessage: LocalMessage = {
        id: v4(),
        role: "user",
        guard_passed: "UNKNOWN",
        text: curreUserMessage.text,
      };

      const currentMessages: LocalMessage[] = [
        ...messagesRef.current,
        userMessage,
      ];

      const payload = {
        chat_history: formatMessages(currentMessages),
        conversation_id: conversationId,
        locale: locale.locale,
      };

      try {
        const updatedMessages = await fetchResponse(
          payload,
          signals,
          currentMessages,
        );

        setMessages(updatedMessages);
      } catch (error) {
        console.log(error);
        signals.onResponse({ error: "error" });
        signals.onClose();
      }
    },
    [messagesRef],
  );

  return (
    <DeepChat
      textInput={createTextInputProps(locale.translations)}
      messageStyles={messageStylesProps}
      chatStyle={chatStyleProps}
      requestBodyLimits={REQUEST_BODY_LIMITS_PROPS}
      request={{ handler: handleRequest }}
      stream={true}
      displayLoadingBubble={true}
      initialMessages={messages}
      htmlClassUtilities={htmlClassUtilities}
      submitButtonStyles={createSubmitButtonStyles(locale.translations)}
    />
  );
};
