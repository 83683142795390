import { TranslationSet } from "./translations";

export const de_de_translations: TranslationSet = {
  chat_message_1:
    "Die Antworten in diesem ChatBot werden mittels KI generiert.",
  chat_message_2:
    "Wenn Sie der Meinung sind, dass etwas nicht ganz richtig aussieht oder wenn die Antwort Ihre Pläne/Ausgaben wesentlich beeinflussen könnte, empfehlen wir Ihnen, die Antwort auf unserer Website oder mit unserem Kundendienst zu überprüfen.",
  chat_message_3:
    "Bitte stellen Sie eine Frage und ich werde mein Bestes tun, um zu helfen.",
  suggestion_1: "Buchung verwalten",
  suggestion_2: "Gepäck",
  suggestion_3: "Zug verspätet",
  close_chat_alt: "Eurostar-Chat-Widget schließen",
  eurostar_logo_alt: "Eurostar-Logo",
  close_eurtostar_button_label: "Eurostar-Chat-Widget schließen",
  toggle_chat_label: "Eurostar-Chat-Widget umschalten",
  chat_label: "Eurostar-Chat-Widget",
  chat_text_placeholder: "Haben Sie Fragen über Eurostar?",
  send_button_text: "Absenden",
  source_text: "Quelle",
  feedback_thumbs_down: "missbilligen",
  feedback_thumbs_up: "billigen",
};
